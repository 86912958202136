import React from 'react';
import { Avatar, Box, Button, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';

function OrderConfirmationView({ onDone }) {
  const navigate = useNavigate();
  
  const handleDone = () => {
    // First call the parent's handleDone to reset the state
    onDone();
    
    // Then refresh the page by navigating to the current path
    const currentPath = window.location.pathname;
    navigate(currentPath, { replace: true });
    
    // Force a page refresh
    window.location.reload();
  };

  return (
    <Box sx={{ p: 3, textAlign: 'center' }}>
      <Avatar sx={{ bgcolor: 'primary.main', mx: 'auto', mb: 2 }}>
        <CheckCircleIcon />
      </Avatar>
      <Typography variant="h5" gutterBottom>Order submitted</Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        We'll email you once this order changes status.
      </Typography>
      <Button 
        variant="contained" 
        color="primary" 
        fullWidth 
        sx={{ mb: 2 }} 
        onClick={handleDone}
      >
        Done
      </Button>
    </Box>
  );
}

export default OrderConfirmationView; 