import React, { useState } from 'react';
import { Box, Card, CardContent, Typography, Grid, Avatar, Button, Snackbar, Link } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';

const TermsOfService = () => {
  const currentDate = 'April 30, 2023';
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { auth } = useAuth();
  const baseUrl = window.buildENV === 'staging' ? 'https://stage.goldwaterbullion.com' : 'https://www.goldwaterbullion.com';

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const postTosAcceptance = async () => {
    const isLocal = process.env.REACT_APP_LOCAL === 'true';

    if (isLocal) {
      try {
        const fixtureData = await import('./fixtures/tos_accepted.json');
        console.log('Mock TOS acceptance:', fixtureData);
        setOpenSnackbar(true);
      } catch (err) {
      } finally {
      }
    } else {
      try {
        const response = await fetch('/tos/accepted', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ accepted: true }),
        });
        if (!response.ok) {
          throw new Error('Failed to accept Terms of Service');
        }
        setOpenSnackbar(true);
      } catch (err) {
        console.log(err.message);
      } finally {
      }
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', padding: '40px' }}>
      <Grid container spacing={4} sx={{ maxWidth: '1200px' }}>
        <Grid item xs={12}>
          <Card sx={{ boxShadow: 3, borderRadius: 2, padding: '20px', textAlign: 'left', }}>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                <Avatar sx={{ bgcolor: 'primary.main', marginRight: '10px' }}>L</Avatar>
                <Box>
                  <Typography variant="h3">Legal</Typography>
                  <Typography variant="caption">{currentDate}</Typography>
                </Box>
              </Box>

              <Typography variant="h6" sx={{ marginBottom: '20px' }}>
                Terms of Service
              </Typography>

              <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
                These Terms of Service (“Terms”) constitute a legally binding agreement between you (“User,” “you,” or “your”) and Goldwater Bullion Exchange, Inc. (“Goldwater Bullion Exchange,” “we,” “us,” or “our”). By accessing, using, or placing orders through the Goldwater Bullion Exchange website or related services (collectively, the “Services”), you agree to be bound by these Terms. If you do not agree to these Terms, you must discontinue use of the Services immediately.
              </Typography>

              <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
                At Goldwater Bullion Exchange, we are committed to ensuring your satisfaction with every transaction. We strive to provide you with a secure, reliable, and transparent marketplace for buying and selling gold, silver, and other precious metals. Your satisfaction and confidence in our process are our top priorities.
              </Typography>

              <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 500 }}>
                1. Scope of Services
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
                Goldwater Bullion Exchange operates as a marketplace, matching buyers and sellers of gold, silver, and other precious metals. All transactions are subject to verification, and payment is held securely until the shipment is confirmed.
              </Typography>

              <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 500 }}>
                2. Eligibility
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
                By accessing or using the services provided by Goldwater Bullion Exchange (GBX), you represent and warrant that you are at least eighteen (18) years of age and have the legal capacity to enter into binding agreements under applicable law. You further affirm that you are a resident of the United States and acknowledge that the use of our services is limited to individuals and entities within the United States.
              </Typography>

              <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 500 }}>
                3. Account Registration
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
                To access the full range of Services, you must create an account. You are responsible for safeguarding your account credentials and for any activity conducted through your account. Notify us immediately if you suspect any unauthorized access. We reserve the right to suspend or terminate your account at any time for violation of these Terms or any suspicious activity.
              </Typography>

              <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 500 }}>
                4. Minimum and Maximum Order Value
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
                Each order placed on the GBX platform must comply with the following value requirements: a minimum order value of $25 and a maximum order value of $100,000 per transaction. GBX reserves the right to review or decline any orders exceeding these limits. Additionally, we do not facilitate sales or shipments outside the United States. Any attempt to place an order from, or ship products to, a location outside the U.S. will result in the cancellation of the transaction without liability to GBX.
              </Typography>

              <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 500 }}>
                5. Order Price Lock-In Policy
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
              At Goldwater Bullion Exchange (GBX), the Order Price Lock-In Policy ensures that once a bid matches an ask, the agreed price becomes final and binding for both parties. This feature transforms each trade into a contractual agreement between the buyer and seller, giving users the power to set prices that meet their specific needs.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
                When a trade is confirmed, the buyer’s funds are held securely in escrow until the product is shipped and verified at our Texas metal verification facility. Upon verification, the funds are released to the seller, ensuring both parties fulfill their commitments without concern over delays or disputes.
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
                This policy offers protection to both parties by guaranteeing the terms of the trade are honored. Sellers are assured they will receive the agreed price regardless of any subsequent market changes, while buyers know their payment will only be released once the product is verified. Any attempt to cancel a matched trade after the price is locked will trigger the penalties detailed in Section 7 – Trade Cancellations and Market Loss Policy.
                The lock-in policy supports a fair, transparent marketplace where users can set and agree upon prices freely, ensuring that trades are conducted with mutual trust and accountability.
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 500 }}>
                6. Fees
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            In general, Goldwater Bullion Exchange makes money when you purchase or sell Bullion on the Goldwater Bullion Exchange Site. By using our Services, you agree to pay all applicable fees, which may include transaction fees, shipping fees, and other service-related charges. For sellers, a 5.6% application fee is deducted from the total transaction value upon the successful sale of an item. Additionally, both buyers and sellers are subject to a $0 shipping fee for each shipment, which is non-refundable. Shipping fees will be included in the final transaction summary and deducted from the seller’s payout or added to the buyer’s invoice.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            We reserve the right to adjust our fee structure, waive fees, or introduce new fees at our discretion. Where required by law, we will provide at least 30 days' prior notice of such changes. All fees, including shipping and application fees, are disclosed at the time of transaction authorization and reflected in the transaction receipt.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            Goldwater Bullion Exchange may also charge third-party bank fees for processing ACH or wire transfers, and you are responsible for any additional fees imposed by your financial institution. If bank fees exceed the transfer amount, the transaction may be canceled unless additional funds are deposited to cover the deficit. We do not accept cash or checks on delivery (COD) payments. In the event that a fee dispute arises, users agree to resolve the matter through arbitration as outlined in the Dispute Resolution section of these Terms.
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 500 }}>
            7. TRADE CANCELLATIONS AND MARKET LOSS POLICY
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            At Goldwater Bullion Exchange (GBX), both buyers and sellers place bids and asks with the intention of executing trades. While bids can be canceled at any time before they are matched, asks become binding once they are matched with a bid, and the trade must proceed. Our Market Loss Policy is designed to protect both parties in a transaction, ensuring fairness and accountability in cases where a matched trade is canceled or obligations are not fulfilled.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
                If you place an ask (an offer to sell) and the trade is confirmed with a matching bid, you are obligated to ship the product according to our guidelines. Likewise, the buyer must ensure their payment is available and ready for the transaction. Once matched, the transaction is final, and cancellations are not allowed without penalties. If an ask is canceled after matching, or if either party fails to meet their obligations (such as shipping or payment), the greater of $50 or 5% of the total transaction value will be charged as a cancellation fee, along with any Market Loss resulting from changes in the metal price.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
                Market Loss is calculated based on adverse price fluctuations between the time of the confirmed trade and the time of cancellation. This ensures that the non-canceling party is not unfairly disadvantaged by market changes. Users who cancel confirmed trades are required to pay any outstanding fees and Market Loss promptly. Until all fees are paid, no further trades will be permitted on the platform.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            Market Gains, when applicable, will remain the property of GBX to maintain platform integrity and market stability. This policy ensures that both buyers and sellers act in good faith, protecting the interests of all users and creating a trusted trading environment. By participating in the GBX marketplace, users agree to these terms, acknowledging the importance of mutual accountability in protecting both buyers and sellers
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 500 }}>
                8. Payment Methods & Fund Clearance
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
                We accept the following payment methods: ACH Transfers, Wire Transfers, and Checks. Payments are only considered 
                cleared upon successful processing. Shipments will not be released until payment has been verified as good funds. 
                Goldwater Bullion Exchange does not accept third-party payments or cash on delivery (COD) transactions.
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            <b>9. Shipping Policy</b>
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8, paddingLeft: '20px' }}>
            We want you to be satisfied, and to protect your assets, we require compliance with our shipping guidelines 
            to ensure safe and secure delivery.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '10px', fontWeight: 600, paddingLeft: '20px' }}>
            A. Packaging Requirements
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '10px', lineHeight: 1.8, paddingLeft: '40px' }}>
            <b>Double Boxing:</b> All shipments must be double-boxed, meaning the contents are secured within two new, 
            unused cardboard boxes. This ensures maximum protection during transit. Pad the packages with packing paper so 
            nothing is loose. Tape the edges.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '10px', lineHeight: 1.8, paddingLeft: '40px' }}>
            <b>Photograph Contents:</b> Photograph the contents before sealing the inner box.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '10px', lineHeight: 1.8, paddingLeft: '40px' }}>
            <b>Labeling:</b> Clearly label the outer box and include a copy of the label on the inner box. Do not 
            disclose the contents to carrier personnel to safeguard against theft.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8, paddingLeft: '20px' }}>
            Failure to follow these instructions may result in denial of any insurance claims in the event of damage or loss.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '10px', fontWeight: 600, paddingLeft: '20px' }}>
            B. Insurance for Shipments
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '10px', lineHeight: 1.8, paddingLeft: '40px' }}>
            We insure all shipments for their full sales value while in transit. Insurance coverage is subject to the following conditions:
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '10px', lineHeight: 1.8, paddingLeft: '60px' }}>
            <b>Physical Presence Required:</b> You must be physically present at the delivery address to accept the package.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '10px', lineHeight: 1.8, paddingLeft: '60px' }}>
            <b>Timely Reporting:</b> Lost packages must be reported to us within 21 days of shipment. Any damage or 
            tampering must be reported within 2 days of delivery. Failure to notify us within these timeframes may jeopardize 
            insurance coverage.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '10px', lineHeight: 1.8, paddingLeft: '60px' }}>
            <b>Delivery Restrictions:</b> We are not responsible for packages left unattended or delivered to unauthorized 
            persons. If you authorize the carrier to leave the package at an alternate location (e.g., neighbor, mailroom, 
            third-party drop-off), you assume all risks of loss or damage.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '10px', lineHeight: 1.8, paddingLeft: '60px' }}>
            <b>Cooperation in Recovery:</b> In the event of a lost or damaged package, you agree to cooperate fully with 
            Goldwater Bullion Exchange, the carrier, and the insurance provider. This may include signing affidavits, filing police 
            reports, and providing all requested information.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '10px', lineHeight: 1.8, paddingLeft: '60px' }}>
            <b>Claims Process:</b> If your shipment is lost or damaged, Goldwater Bullion Exchange will pursue a claim 
            on your behalf. At our discretion, we may re-ship your items or issue a refund after the claim is resolved.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8, paddingLeft: '60px' }}>
            <b>End of Liability:</b> Goldwater Bullion Exchange’s responsibility ends once the package is marked as 
            delivered by the carrier.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '10px', fontWeight: 600, paddingLeft: '20px' }}>
            C. Back-Ordered Items
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '10px', lineHeight: 1.8, paddingLeft: '40px' }}>
            In the rare event that an item is back-ordered, we may delay delivery up to 28 calendar days. During this time, 
            your locked-in price will remain valid. If we cannot fulfill the order, we will offer the following options:
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '10px', lineHeight: 1.8, paddingLeft: '60px' }}>
            1. Place the item on back order.<br />
            2. Attempt to source it from another supplier.<br />
            3. Issue a refund.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8, paddingLeft: '40px' }}>
            You waive the right to claim damages for delays beyond this period, including fluctuations in the market price 
            of precious metals.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            <b>10. Refund and Return Policy</b>
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            At Goldwater Bullion Exchange, your satisfaction is our priority. While all sales are final, we are committed 
            to ensuring you have a positive experience. If there is an issue with your purchase, we will contact the seller 
            on your behalf to seek a resolution.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            Refunds will only be issued under exceptional circumstances, such as non-delivery of an item or verified damage. 
            Refunds are subject to our sole discretion and may take up to 10 business days to process. Shipping and handling 
            fees are non-refundable.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            <b>11. Risk Disclosure</b>
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            Investing in gold, silver, and other precious metals involves risks, including price fluctuations and market volatility. 
            Goldwater Bullion Exchange makes no guarantees regarding the future value of any item. We recommend consulting with a financial 
            advisor before making significant investments.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            <b>12. Binding Contract</b>
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            All matched trades constitute a legally binding agreement between the buyer and seller. Attempts to cancel or modify confirmed 
            trades will result in penalties, including market loss fees.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            <b>13. Limitation of Liability</b>
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            Goldwater Bullion Exchange is not liable for:
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '10px', lineHeight: 1.8, paddingLeft: '20px' }}>
            • Delays caused by shipping carriers.<br />
            • Loss or damage resulting from failure to follow shipping instructions.<br />
            • Market fluctuations affecting the value of purchased items.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '10px', lineHeight: 1.8, paddingLeft: '20px' }}>
            In no event shall Goldwater Bullion Exchange be liable for indirect, incidental, or consequential damages arising 
            from your use of the Services.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            <b>14. Cancellation Rights & Termination</b>
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            We reserve the right to cancel any order or terminate any account at our discretion. If an order is canceled by us, 
            any payments made will be refunded, less any applicable fees.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            <b>15. Dispute Resolution & Governing Law</b>
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            These Terms are governed by the laws of the State of Texas, without regard to conflict of law principles. Any disputes 
            will be resolved through binding arbitration in Texas. Users waive the right to participate in class actions or pursue 
            claims through the court system.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            <b>16. Force Majeure</b>
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            Goldwater Bullion Exchange is not responsible for delays or failure to perform due to events beyond our control, including 
            natural disasters, labor disputes, or service interruptions.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            <b>17. Entire Agreement</b>
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            These Terms constitute the entire agreement between you and Goldwater Bullion Exchange, superseding all prior agreements 
            and understandings.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            <b>18. Severability</b>
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall remain in full 
            force and effect.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            <b>19. Contact Information</b>
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
            For any questions or concerns regarding these Terms, please contact us at:
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8, paddingLeft: '20px' }}>
            Goldwater Bullion Exchange, Inc.<br />
            5900 Balcones Drive, STE 100<br />
            Austin, TX 78731<br />
            </Typography>
            </CardContent>
            {/* Accept Terms Button */}
            {(auth?.user && !auth.user.tos_accepted) && (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={postTosAcceptance}
                >
                    Accept Terms of Service
                </Button>
            </Box>
            )}

            {/* Link to Homepage after acceptance */}
            {(auth?.user &&auth.user.tos_accepted) && (
                <>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8 }}>
                 Terms of Service accepted - Your finished: 
                 </Typography>
                 </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Button
                  onClick={() => (window.location.href = `${baseUrl}`)}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Go to Homepage
                </Button>
                  </Box>
               </>
            )}
          </Card>
        </Grid>
      </Grid>
      {/* Snackbar Notification */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Terms of Service accepted successfully"
      />
    </Box>
    
  );
};

export default TermsOfService;
