import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Button, Modal, Box, Slider, Badge, Select, MenuItem, FormControl, InputLabel, Chip, Collapse } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './ProductFilter.css';
import CloseIcon from '@mui/icons-material/Close';
import 'boxicons';
import { styled } from '@mui/material/styles';

// Add blinking dot animation
const BlinkingDot = styled('span')(({ theme }) => ({
  display: 'inline-block',
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: '#4CAF50',
  marginRight: 8,
  animation: 'blink 1s ease-in-out infinite',
  '@keyframes blink': {
    '0%': { opacity: 0 },
    '50%': { opacity: 1 },
    '100%': { opacity: 0 },
  }
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const weightMarks = [
  { value: 0, label: '1/10 oz' },
  { value: 25, label: '1/4 oz' },
  { value: 50, label: '1/2 oz' },
  { value: 75, label: '1 oz' },
  { value: 90, label: '5 oz' },
  { value: 100, label: '1 kg' }
];

const GOLD_PURITIES = [
  0.9999, 0.999, 0.99,
  0.9167, 0.9
];

const scaleValueToOunces = (value) => {
  if (value <= 25) return 0.1 + (value / 25) * 0.15;
  if (value <= 50) return 0.25 + ((value - 25) / 25) * 0.25;
  if (value <= 75) return 0.5 + ((value - 50) / 25) * 0.5;
  if (value <= 90) return 1 + ((value - 75) / 15) * 4;
  return 5 + ((value - 90) / 10) * 27.15;
};

const cacheProductsInLocalStorage = (products) => {
  products.forEach(product => {
    const { product_id, title, image_one_thumb } = product;
    const cachedProduct = { title, thumbnail: image_one_thumb };
    localStorage.setItem(product_id, JSON.stringify(cachedProduct));
  });
};

const mints = [
  "United States Mint",
  "Royal Canadian Mint",
  "Perth Mint",
  "Royal Mint",
  "Austrian Mint",
  "China Mint",
  "South African Mint",
  "Mexican Mint",
  "Swiss Mint",
  "Japan Mint",
  "Monnaie de Paris",
  "New Zealand Mint",
  "Royal Australian Mint",
  "Korean Mint (KOMSCO)",
  "Russian Mint (Goznak)",
  "Royal Dutch Mint",
  "Italian Mint",
  "Central Bank of Brazil",
  "Spanish Royal Mint",
  "PAMP Suisse",
  "Valcambi Suisse",
  "Credit Suisse",
  "Johnson Matthey",
  "Engelhard",
  "Heraeus",
  "Sunshine Minting",
  "Scottsdale Mint",
  "Asahi Refining",
  "Kitco",
  "Elemetal Mint",
  "Argor-Heraeus",
  "Mitsubishi Materials Corporation",
  "Metalor Technologies",
  "Istanbul Gold Refinery",
  "Republic Metals Corporation",
  "Bavarian State Mint",
  "Casa de Moneda de Colombia",
  "Royal Belgian Mint",
  "The Royal Mint of Norway",
  "Hungarian Mint",
  "Royal Mint of Denmark",
  "Greek Mint",
  "Finnish Mint",
  "Polish Mint",
  "Bulgarian Mint",
  "Generic"
];

const Main = () => {
  const query = useQuery();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [weightRange, setWeightRange] = useState([0, 100]);
  const [activeFilters, setActiveFilters] = useState(0);
  const [selectedMint, setSelectedMint] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const isLocal = process.env.REACT_APP_LOCAL === 'true';
  const [selectedPurity, setSelectedPurity] = useState('');
  const goldSpotPrice = 2654.33;
  const silverSpotPrice = 29.33;
  const [expandedRow, setExpandedRow] = useState(null);

  const calculateCost = (product) => {
    if (!product.stripe_payment_link || Object.keys(product.stripe_payment_link).length === 0) {
      return null;
    }
    // Parse prices and convert to numbers with 2 decimal places
    const prices = Object.keys(product.stripe_payment_link).map(price => 
      Number(parseFloat(price))
    );
    return Math.min(...prices);
  };

  const calculateCashPremium = (product) => {
    const spotPrice = product.material === 'Gold' ? goldSpotPrice : silverSpotPrice;
    return spotPrice * (product.premium_percentage / 100);
  };

  const getActiveFiltersCount = (params) => {
    let count = 0;
    params.forEach((value, key) => {
      if (value !== null && value !== '' && value !== 'null') {
        count++;
      }
    });
    return count;
  };

  const fetchProducts = useCallback(async (queryString) => {
    if (isLocal) {
      try {
        const staticResponse = await import('./fixtures/products.json');
        setProducts(staticResponse.default);
        cacheProductsInLocalStorage(staticResponse.default);
      } catch (error) {
        console.error('Error loading static data:', error);
        setError('Error loading static data');
      } finally {
        setLoading(false);
      }
    } else {
      const apiUrl = `/api/products${queryString}`;
      try {
        const response = await axios.get(apiUrl);
        setProducts(response.data);
        cacheProductsInLocalStorage(response.data);
      } catch (error) {
        console.error('Error fetching product info:', error);
        setError('Error fetching product info');
      } finally {
        setLoading(false);
      }
    }
  }, [isLocal]);

  useEffect(() => {
    fetchProducts(location.search);
  }, [fetchProducts, location.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const count = getActiveFiltersCount(queryParams);
    setActiveFilters(count);
  }, [location.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    
    const manufacturer = queryParams.get('manufacturer') || '';
    const purity = queryParams.get('purity') || '';
  
    setSelectedMint(manufacturer);
    setSelectedPurity(purity);
  }, [location.search]);

  const updateQueryString = useCallback((updates) => {
    const newQuery = new URLSearchParams(location.search);
  
    Object.entries(updates).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        newQuery.set(key, value);
      } else {
        newQuery.delete(key);
      }
    });
  
    navigate({ search: `?${newQuery.toString()}` }, { replace: true });
  }, [navigate, location.search]);
  

  const handleWeightChange = (event, newValue) => {
    setWeightRange(newValue);
  };


  const handlePurityChange = (event) => {
    const value = event.target.value;
    setSelectedPurity(value);
  
    if (value === "") {
      updateQueryString({ purity: null }); // Remove the purity filter
    }
  };
  

  const applyFilters = () => {
    const minWeight = scaleValueToOunces(weightRange[0]);
    const maxWeight = scaleValueToOunces(weightRange[1]);
  
    const weightFilters = {};
    const purityFilter = selectedPurity ? { purity: selectedPurity } : { purity: null }; // Handle 'None' selection
  
    if (weightRange[0] !== 0) weightFilters.min_weight = minWeight;
    if (weightRange[1] !== 100) weightFilters.max_weight = maxWeight;
  
    if (weightRange[0] === 0 && weightRange[1] === 100) {
      weightFilters.min_weight = null;
      weightFilters.max_weight = null;
    }
  
    updateQueryString({ ...weightFilters, ...purityFilter }); // Include purity in query
    setFilterModalOpen(false);
  };
  
  const handleMintChange = (event) => {
    const value = event.target.value;
    setSelectedMint(value);
  
    if (value === "") {
      updateQueryString({ manufacturer: null });
    } else {
      updateQueryString({ manufacturer: value });
    }
  };

  const handleRowClick = (productId) => {
    setExpandedRow(expandedRow === productId ? null : productId);
  };

  const generateMockPriceData = () => {
    const points = 10;
    const data = [];
    let lastPrice = Math.random() * 100;
    
    for (let i = 0; i < points; i++) {
      lastPrice += (Math.random() - 0.5) * 10;
      data.push(lastPrice);
    }
    return data;
  };

  const renderSparkline = (data) => {
    const width = 50;
    const height = 20;
    const points = data.length;
    const max = Math.max(...data);
    const min = Math.min(...data);
    const range = max - min;
    
    const points_string = data.map((value, index) => {
      const x = (index / (points - 1)) * width;
      const y = height - ((value - min) / range) * height;
      return `${x},${y}`;
    }).join(' ');

    const isPositive = data[data.length - 1] > data[0];
    const strokeColor = isPositive ? '#4CAF50' : '#f44336';

    return (
      <svg width={width} height={height}>
        <polyline
          points={points_string}
          fill="none"
          stroke={strokeColor}
          strokeWidth="1"
        />
      </svg>
    );
  };

  const getLowestPrice = (product) => {
    const prices = [
      calculateCost(product),
      product.apmex_cost,
      product.jm_bullion_cost
    ].filter(price => price && !isNaN(price));
    
    return Math.min(...prices);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div style={{ width: '100%', margin: 0, padding: 0 }}>
      <TableContainer component={Paper} sx={{ maxHeight: '70vh', width: '100%', margin: 0, padding: 0 }}>
      <Table stickyHeader sx={{ width: '100%', tableLayout: 'fixed' }}>
      <TableHead>
            <TableRow>
              <TableCell colSpan={9} sx={{ borderBottom: 'none', pb: 0 }}>
                <Box sx={{ display: 'flex', gap: 1, justifyContent: 'space-between', mb: 1, alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginLeft: 'auto' }}>
                    <Chip 
                      icon={<BlinkingDot />}
                      label={`Gold Spot: $${goldSpotPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                      sx={{ color: 'black', borderColor: 'black' }}
                      variant="outlined"
                    />
                    <Chip 
                      icon={<BlinkingDot />}
                      label={`Silver Spot: $${silverSpotPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                      sx={{ color: 'black', borderColor: 'black' }}
                      variant="outlined"
                    />
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Asks</TableCell>
              <TableCell>Cost</TableCell>
              <TableCell>Premium (%)</TableCell>
              <TableCell>Cash Premium</TableCell>
              <TableCell>APMEX</TableCell>
              <TableCell>JM Bullion</TableCell>
              <TableCell>Trend</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => {
              const ourCost = calculateCost(product);
              const lowestPrice = getLowestPrice(product);
              const isExpanded = expandedRow === product.product_id;
              const askCount = product.stripe_payment_link ? Object.keys(product.stripe_payment_link).length : 0;
              
              return (
                <React.Fragment key={product.product_id}>
                  <TableRow 
                    hover
                    onClick={() => handleRowClick(product.product_id)}
                    sx={{ cursor: 'pointer', '& > *': { borderBottom: 'unset' } }}
                  >
                    <TableCell>
                      <img 
                        src={product.image_one_thumb} 
                        alt={product.title}
                        style={{ width: 50, height: 50, objectFit: 'contain' }}
                      />
                    </TableCell>
                    <TableCell>{product.title}</TableCell>
                    <TableCell>{askCount}</TableCell>
                    <TableCell>
                      {ourCost === lowestPrice ? (
                        <Chip label={`$${ourCost?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`} color="success" size="small" />
                      ) : (
                        ourCost ? `$${ourCost?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : 'No asks'
                      )}
                    </TableCell>
                    <TableCell>{product.premium_percentage.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%</TableCell>
                    <TableCell>${calculateCashPremium(product).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                    <TableCell>
                      {product.apmex_cost === lowestPrice ? (
                        <Chip label={`$${product.apmex_cost?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`} color="success" size="small" />
                      ) : (
                        `$${product.apmex_cost?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                      )}
                    </TableCell>
                    <TableCell>
                      {product.jm_bullion_cost === lowestPrice ? (
                        <Chip label={`$${product.jm_bullion_cost?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`} color="success" size="small" />
                      ) : (
                        `$${product.jm_bullion_cost?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                      )}
                    </TableCell>
                    <TableCell>{renderSparkline(generateMockPriceData())}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            Asks
                          </Typography>
                          {product.stripe_payment_link && Object.keys(product.stripe_payment_link).length > 0 ? (
                            Object.entries(product.stripe_payment_link).map(([price, links]) => (
                              <Box key={price} sx={{ mb: 2 }}>
                                <Typography variant="subtitle1" gutterBottom>
                                  Price: ${parseFloat(price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </Typography>
                                {links.map((link, linkIndex) => (
                                  <Button
                                    key={linkIndex}
                                    variant="contained"
                                    color="primary"
                                    href={link}
                                    sx={{ m: 1 }}
                                  >
                                    ${parseFloat(price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                  </Button>
                                ))}
                              </Box>
                            ))
                          ) : (
                            <Typography color="text.secondary">No payment links available</Typography>
                          )}
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal 
  open={filterModalOpen} 
  onClose={() => setFilterModalOpen(false)}
  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} // Center the modal
>
  <Box
    sx={{
      position: 'relative',  // Ensure the close button is positioned relative to this Box
      width: 400,
      bgcolor: 'background.paper',
      boxShadow: 24,
      borderRadius: 2,
      p: 4,  // Padding inside the modal
    }}
  >
    {/* Close Icon */}
    <IconButton
      aria-label="close"
      onClick={() => setFilterModalOpen(false)}
      sx={{
        position: 'absolute',
        top: 8,
        right: 8,
      }}
    >
      <CloseIcon />
    </IconButton>

    <Typography variant="h5" gutterBottom>
      Filters
    </Typography>
    <Divider />
    <Typography variant="body1" sx={{ marginTop: 2 }} gutterBottom>
      Filter by Coin Weight
    </Typography>
    <Slider
      value={weightRange}
      onChange={handleWeightChange}
      valueLabelDisplay="off"
      step={1}
      marks={weightMarks}
      min={0}
      max={100}
      sx={{
        '& .MuiSlider-mark': {
          marginLeft: '-10px',
          marginRight: '-10px',
        },
        '& .MuiSlider-markLabel': {
          transform: 'translateX(-10%)',
        },
      }}
    />
    <Divider />
    <Typography variant="body1" sx={{ marginTop: 2 }} gutterBottom>
      Producers
    </Typography>
    <FormControl fullWidth sx={{ marginTop: 2 }}>
      <InputLabel id="mint-select-label">Select Mint</InputLabel>
      <Select
        labelId="mint-select-label"
        value={selectedMint}
        onChange={handleMintChange}
      >
        <MenuItem value="">None</MenuItem>
        {mints.map((mint) => (
          <MenuItem key={mint} value={mint}>
            {mint}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    <Divider />
    <Typography variant="body1" sx={{ mt: 2 }} gutterBottom>
      Filter by Purity
    </Typography>
    <FormControl fullWidth sx={{ marginTop: 2 }}>
      <InputLabel id="purity-select-label">Select Purity</InputLabel>
      <Select
        labelId="purity-select-label"
        value={selectedPurity}
        onChange={handlePurityChange}
      >
        <MenuItem value="">None</MenuItem> {/* Add a default empty option */}
        {GOLD_PURITIES.map((purity) => (
          <MenuItem key={purity} value={purity.toString()}>
            {(purity * 100).toFixed(2)}%
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    <Button variant="contained" onClick={applyFilters} sx={{ marginTop: 2 }}>
      Apply Filter
    </Button>
  </Box>
</Modal>

    </div>
  );
};

export default Main;
