import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import axios from 'axios';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import CustomNoRowsOverlay from './CustomNoRowsOverlay';

const FulfillmentPage = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('all');
  const navigate = useNavigate();

  const fetchOrders = useCallback(async () => {
    const isLocal = process.env.REACT_APP_LOCAL === 'true';

    if (isLocal) {
      try {
        const mockData = await import('./fixtures/trades.json');
        setOrders(mockData.default);
        setFilteredOrders(mockData.default);
      } catch (error) {
        console.error('Error loading mock data:', error);
        setError('Failed to load mock data');
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const response = await axios.get('/api/orders/sell/trades');
        setOrders(response.data);
        setFilteredOrders(response.data);
      } catch (error) {
        console.error('Error fetching orders:', error);
        setError('Failed to fetch orders');
      } finally {
        setLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const handleFilterChange = (event, newFilter) => {
    setFilter(newFilter || 'all');
    applyFilter(newFilter || 'all');
  };

  const statusFilteredOrders = orders.filter(order => 
    order.status === 'awaiting_shipment' || 
    order.status === 'facility_label_created' ||
    order.status === 'shipment_label_created' 
  );

  const fullyFilteredOrders = statusFilteredOrders.filter(order => 
    order.vendor_shipment_status === null ||
    order.vendor_shipment_status === 'pre_transit' ||
    order.vendor_shipment_status === 'failure' ||
    order.vendor_shipment_status === 'unknown'
  );

  const shippedOrders = orders.filter(order => 
    order.vendor_shipment_status === 'in_transit' ||
    order.vendor_shipment_status === 'out_for_delivery' ||
    order.vendor_shipment_status === 'delivered' ||
    order.vendor_shipment_status === 'return_to_sender'
  );

  const applyFilter = (filter) => {
    if (filter === 'all') {
      setFilteredOrders(orders);
    } else if (filter === 'unfulfilled') {
      setFilteredOrders(fullyFilteredOrders);
    } else if (filter === 'unpaid') {
      setFilteredOrders(orders.filter(order => order.paid_status !== 'cleared'));
    } else if (filter === 'in_transit') {
      setFilteredOrders(orders.filter(order => order.vendor_shipment_status === 'in_transit'));
    } else if (filter === 'archived') {
      setFilteredOrders(orders.filter(order => order.paid_status === 'cleared')); 
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  const columns = [
    { field: 'order_no', headerName: 'Trade', width: 120 },
    {
      field: 'date',
      headerName: 'Date',
      width: 180,
      sortComparator: (v1, v2) => {
        const date1 = new Date(v1).getTime();
        const date2 = new Date(v2).getTime();
        return date1 - date2;
      }
    },
    {
      field: 'total',
      headerName: 'Total',
      width: 120,
      sortComparator: (v1, v2) => {
        const total1 = parseFloat(v1.replace(/[$,]/g, ''));
        const total2 = parseFloat(v2.replace(/[$,]/g, '')); 
        return total1 - total2;
      }
    },
    { 
      field: 'paid_status', 
      headerName: 'Payment Status', 
      width: 150, 
      renderCell: (params) => (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Chip 
            label={params.value ? params.value : 'Unpaid'} 
            variant="outlined" 
            size="small" 
            color={params.value === 'cleared' ? 'success' : params.value === 'pending' ? 'warning' : 'default'} 
            sx={{ margin: 'auto', verticalAlign: 'middle' }} 
          />
        </Box>
      ) 
    },
    {
      field: 'fulfillment_status',
      headerName: 'Fulfillment Status',
      width: 180,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Chip 
            size="small" 
            label={params.value} 
            color={
              params.value === 'Fulfilled'
                ? 'success'
                : params.value === 'Shipped'
                ? 'info'
                : 'warning'
            }
            sx={{ margin: 'auto', verticalAlign: 'middle' }} 
          />
        </Box>
      )
    },    
    { 
      field: 'product_verified', 
      headerName: 'Product Verified', 
      width: 180, 
      renderCell: (params) => (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Chip 
            size="small" 
            label={params.value ? 'Verified' : 'Not Verified'}
            color={params.value ? 'success' : 'warning'}
            sx={{ margin: 'auto', verticalAlign: 'middle' }} 
          />
        </Box>
      )
    },
    { field: 'items', headerName: 'Items', width: 100 }
  ];
  
  const formatToDollars = (cents) => {
    return `$${(cents / 100).toFixed(2)}`;
  };

  const getFulfillmentStatus = (vendorShipmentStatus, tradeStatus) => {
    if (vendorShipmentStatus === 'delivered') {
      return 'Fulfilled';
    } else if (vendorShipmentStatus === 'in_transit' || vendorShipmentStatus === 'out_for_delivery') {
      return 'Shipped';
    } else if (
      tradeStatus === 'facility_label_created' ||
      tradeStatus === 'awaiting_shipment' ||
      tradeStatus === 'shipment_label_created'
    ) {
      return 'Unfulfilled';
    } else {
      return 'Unfulfilled';
    }
  }; 

  const rows = filteredOrders.map((trade, index) => ({
    id: index,
    trade_uuid: trade.trade_uuid,
    order_no: `${trade.trade_uuid.slice(-6)}`,
    date: trade.timestamp ? new Date(trade.timestamp).toLocaleString() : 'No Date',
    total: `${formatToDollars(trade.volume * trade.price)}`,
    paid_status: trade.paid_status,
    fulfillment_status: getFulfillmentStatus(trade.vendor_shipment_status, trade.status),
    items: `${trade.volume} item(s)`,
    product_verified: trade.product_verified
  }));

  const handleRowClick = (params) => {
    const trade_uuid = params.row.trade_uuid;
    navigate(`/trades/${trade_uuid}`);
  };

  return (
    <div style={{ padding: '10px' }}>
      <Typography variant="h6" gutterBottom align="left">
        Fulfillment
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
        <ToggleButtonGroup
          value={filter}
          exclusive
          onChange={handleFilterChange}
          sx={{ marginBottom: '10px', width: '100%', height: '36px' }}
          aria-label="filter orders"
        >
          <ToggleButton value="all" aria-label="all orders" sx={{ width: '20%', height: '36px', borderRadius: '0px', fontSize: '0.75rem' }}>
            ALL
          </ToggleButton>
          <ToggleButton value="unfulfilled" aria-label="unfulfilled orders" sx={{ width: '20%', height: '36px', borderRadius: '0px', fontSize: '0.75rem' }}>
            UNFULFILLED
          </ToggleButton>
          <ToggleButton value="unpaid" aria-label="unpaid orders" sx={{ width: '20%', height: '36px', borderRadius: '0px', fontSize: '0.75rem' }}>
            UNPAID
          </ToggleButton>
          <ToggleButton value="in_transit" aria-label="open orders" sx={{ width: '20%', height: '36px', borderRadius: '0px', fontSize: '0.75rem' }}>
            In Transit
          </ToggleButton>
          <ToggleButton value="archived" aria-label="archived orders" sx={{ width: '20%', height: '36px', borderRadius: '0px', fontSize: '0.75rem' }}>
            COMPLETE
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <DataGrid 
        rows={rows}
        columns={columns} 
        pageSize={5} 
        rowHeight={40}
        onRowClick={handleRowClick}
        initialState={{
          sorting: {
            sortModel: [{ field: 'date', sort: 'desc' }],
          },
        }}
        slots={{
          noRowsOverlay: () => <CustomNoRowsOverlay filter={filter} />
        }}
        sx={{
          height: 400,  // Set a fixed height
          minHeight: '400px',  // Ensure minimum height
          width: '100%',  // Ensure full width
          fontSize: '0.875rem',
          '& .MuiDataGrid-cell': {
            padding: '10px',
            alignItems: 'center',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#f5f5f5',
            fontSize: '0.875rem',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          }
        }}
      />
    </div>
  );
};

export default FulfillmentPage;
