import React from 'react';
import {
  Box,
  Typography,
  Avatar,
  IconButton,
  Divider,
  Button,
  Alert
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

function ReviewView({
  onBack,
  onFinish,
  errorMessage,
  product,
  selectedTab,
  subTotal,
  inputValue,
  coinInput,
  shippingFee,
  totalPrice,
  appFee = 0,
  paymentMethod = 'wallet'
}) {
  const getButtonText = () => {
    if (selectedTab === 'sell') return 'Sell Now';
    return paymentMethod === 'credit-card' ? 'Continue to Payment' : 'Place Bid';
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconButton onClick={onBack} sx={{ mr: 2 }}>
          <ArrowBack />
        </IconButton>
      </Box>
      {errorMessage && (
        <Alert severity="warning" sx={{ mb: 3 }}>
          {errorMessage}
        </Alert>
      )}
      <Box sx={{ textAlign: 'center', mb: 2 }}>
        <Avatar
          src={product.image_one_thumb || ''}
          alt={product.title}
          sx={{ width: 60, height: 60, mx: 'auto', mb: 1 }}
        />
        <Typography variant="h5">{`${selectedTab === 'buy' ? 'Buy' : 'Sell'} $${subTotal} of ${product.title}`}</Typography>
      </Box>
      <Divider sx={{ mb: 2 }} />
      
      <Box sx={{ display: 'flex', mb: 2 }}>
        <Typography variant="body2" sx={{ flex: 1, textAlign: 'left' }}>{`${selectedTab === 'buy' ? 'Buy' : 'Sell'} Amount`}</Typography>
        <Typography variant="body2" sx={{ flex: 1, textAlign: 'right' }}>{`${coinInput} ${product.title}`}</Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="body2">{selectedTab === 'buy' ? 'Payment Method' : 'Add cash to'}</Typography>
        <Typography variant="body2">
          {selectedTab === 'buy' ? 
            (paymentMethod === 'wallet' ? 'USD Wallet' : 'Credit Card') : 
            'USD Wallet'}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="body2">Shipping</Typography>
        <Typography variant="body2">${shippingFee}</Typography>
      </Box>
      {selectedTab === 'sell' && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="body2">App Fee</Typography>
          <Typography variant="body2">${appFee}</Typography>
        </Box>
      )}
      {paymentMethod === 'credit-card' && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="body2">Credit Card Fee (4%)</Typography>
          <Typography variant="body2">${(subTotal * 0.04).toFixed(2)}</Typography>
        </Box>
      )}
      <Divider sx={{ mb: 2 }} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="body2">Total</Typography>
        <Typography variant="h6">{`$${totalPrice}`}</Typography>
      </Box>
      <Button 
        variant="contained" 
        color="success" 
        fullWidth 
        sx={{ mt: 2 }} 
        onClick={onFinish}
      >
        {getButtonText()}
      </Button>
    </Box>
  );
}

export default ReviewView;
