import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const steps = ['Find your Market', 'Set the Price', 'Ship', 'Payment'];

const SellProcess = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return 'Browse our markets to find the gold or silver you\'re selling. Whether it\'s a 1-ounce Gold Eagle or Silver Maple, we have hundreds of markets.';
      case 1:
        return 'Enter your asking price and the number of coins you want to sell. If there\'s a matching buyer, your order will go through immediately. If not, your listing will stay open until someone meets your price. It\'s all in your control.';
      case 2:
        return 'Once the sale is confirmed, download your fully insured, prepaid shipping label directly from our site. Just print it out, securely pack your gold, double-box it, and drop it off with one of our trusted partners like UPS or FedEx. No hassle, no worries.';
      case 3:
        return 'As soon as we verify your gold at our facility in Texas, you\'ll receive payment via ACH directly to your bank account. It\'s fast and reliable, with payments typically arriving in 1-3 business days.';
      default:
        return 'Unknown step';
    }
  };

  const DesktopView = () => (
    <Card sx={{ width: '100%', padding: 2, boxSizing: 'border-box' }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom sx={{ mb: 4 }}>
          SELLING PROCESS
        </Typography>
        <Stepper activeStep={activeStep} sx={{ pb: 3 }}>
          {steps.map((label, index) => (
            <Step key={label} completed={false}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <Box sx={{ textAlign: 'center' }}>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - Your sale is complete.
            </Typography>
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        ) : (
          <Box>
            <Typography sx={{ mt: 2, mb: 1, textAlign: 'left' }}>
              {getStepContent(activeStep)}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              {activeStep === steps.length - 1 ? null : (
                <Button onClick={handleNext}>
                  {activeStep === steps.length - 1 ? '' : 'Next'}
                </Button>
              )}
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );

  const MobileView = () => (
    <Card sx={{ width: '100%', padding: 2, boxSizing: 'border-box' }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom sx={{ mb: 4, textAlign: 'left' }}>
          SELLING PROCESS
        </Typography>
        <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
          <Typography variant="h6" gutterBottom sx={{ textAlign: 'left' }}>
            {`Step ${activeStep + 1}: ${steps[activeStep]}`}
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'left', mb: 2 }}>
            {getStepContent(activeStep)}
          </Typography>
          <MobileStepper
            variant="text"
            steps={steps.length}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === steps.length - 1}
              >
                Next
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />
        </Box>
      </CardContent>
    </Card>
  );

  return isMobile ? <MobileView /> : <DesktopView />;
};

export default SellProcess;