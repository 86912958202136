import React, { useState, useEffect } from 'react';
import { Container, CircularProgress, Box, Typography, Button } from '@mui/material';
import PaymentMethodsSelector from './PaymentMethodsSelector';
import BankAccountConnection from './BankAccountConnection';
import ConfirmationScreen from './ConfirmationScreen';
import AlertNotification from './AlertNotification';
import FundAccountPreview from './FundAccountPreview';
import { loadStripe } from '@stripe/stripe-js';

const FundingForm = ({ isButtonDisabled, setIsButtonDisabled, onClose }) => {
    const [userPaymentMethods, setUserPaymentMethods] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
    const [amount, setAmount] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [previewMode, setPreviewMode] = useState(false);
    const [confirmationScreen, setConfirmationScreen] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const isLocal = process.env.REACT_APP_LOCAL === 'true';


    const fetchUserName = async () => {
        try {
            let addresses;
            if (isLocal) {
                const fixtureData = await import('./fixtures/addresses.json');
                addresses = fixtureData.default;
            } else {
                const response = await fetch('/api/addresses');
                addresses = await response.json();
            }
    
            if (addresses.length > 0) {
                const [firstName, lastName] = addresses[0].name.split(' ');
                setFirstName(firstName || '');
                setLastName(lastName || '');
            }
        } catch (error) {
            console.error('Error fetching user name:', error);
        }
    };

    const fetchPaymentMethods = async () => {
        setLoading(true);
        try {
            if (isLocal) {
                const fixtureData = await import('./fixtures/payment_methods.json');
                setUserPaymentMethods(fixtureData.user_payment_methods);
            } else {
                const response = await fetch('/api/fund-account/payment-methods');
                const data = await response.json();
                setUserPaymentMethods(data.user_payment_methods || []);
            }
        } catch (error) {
            console.error("Error fetching payment methods:", error);
            setUserPaymentMethods([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPaymentMethods();
        fetchUserName();
    }, []);

    const handleLinkAccount = async () => {
        const response = await fetch('/api/banking/create-session', { method: 'POST' });
        const { client_secret, id } = await response.json();

        const stripe = await loadStripe(window.stripePublishableKey);
        stripe.collectFinancialConnectionsAccounts({
            clientSecret: client_secret,
        }).then(async (result) => {
            if (result.error) {
                console.error(result.error);
            } else {
                const associateResponse = await fetch('/api/banking/associate-account', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id,
                        first_name: firstName,
                        last_name: lastName
                    })
                });

                const associateResult = await associateResponse.json();
                if (associateResult.success) {
                    fetchPaymentMethods();
                } else {
                    console.error('Error associating accounts:', associateResult.error);
                }
            }
        });
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setPreviewMode(true);
    };

    const handleConfirm = async () => {
        const amountInCents = amount * 100;
        setIsButtonDisabled(true);

        try {
            const response = await fetch('/api/banking/fund-account', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    payment_method_id: selectedPaymentMethod,
                    amount: amountInCents
                })
            });

            const result = await response.json();

            if (result.success) {
                setSuccess('Account funded successfully!');
                setConfirmationScreen(true);
                setPreviewMode(false);
                setAmount('');
                setSelectedPaymentMethod('');
            } else {
                setError('Failed to fund account: ' + result.error);
            }
        } catch (error) {
            setError('An error occurred. Please try again.');
        }
    };

    const handleBack = () => {
        setPreviewMode(false);
    };

    const handleCloseAlert = () => {
        setSuccess(null);
        setError(null);
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (confirmationScreen) {
        return <ConfirmationScreen onClose={onClose} />;
    }

    if (userPaymentMethods.length === 0) {
        return <BankAccountConnection firstName={firstName} setFirstName={setFirstName} lastName={lastName} setLastName={setLastName} handleLinkAccount={handleLinkAccount} />;
    }

    if (previewMode) {
        return (
            <FundAccountPreview
                selectedPaymentMethod={selectedPaymentMethod}
                userPaymentMethods={userPaymentMethods}
                amount={amount}
                handleBack={handleBack}
                handleConfirm={handleConfirm}
                isButtonDisabled={isButtonDisabled}
            />
        );
    }

    return (
        <Container maxWidth="md">
            <Box>
                <Typography variant="h6" color="text.primary" gutterBottom>
                    Refund Policy
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2, textAlign: 'left', lineHeight: 1.6 }}>
                    Your satisfaction is our priority. If you're not completely happy with your purchase, you can request a refund within 7 days of receiving your item.
                </Typography>

                <Typography variant="body2" color="text.secondary" sx={{ mb: 2, textAlign: 'left', lineHeight: 1.6 }}>
                    Return of Product: The product must be returned to us in its original condition and packaging. We will not issue a refund until we have received the returned item and verified its condition. Shipping Costs: We will provide you with a fully insured shipping label. You must use our label to receive insurance.
                </Typography>

                <Typography variant="body2" color="text.secondary" sx={{ mb: 2, textAlign: 'left', lineHeight: 1.6 }}>
                    Condition of Returned Items: Items must be returned in their original condition. This includes any packaging, documentation, and accessories that were included with the product. Coins must show no signs of additional wear or tampering beyond what was described at the time of purchase.
                </Typography>

                <Typography variant="body2" color="text.secondary" sx={{ mb: 2, textAlign: 'left', lineHeight: 1.6 }}>
                    Refund Processing: Once we have received and inspected the returned item, we will process your refund. Refunds will be issued to the original method of payment. Please note that it might take several business days for the refund to reflect in your account, depending on your bank or credit card company's policies. Exceptions: Certain items may be non-returnable due to their nature (e.g., custom orders, special edition items).
                </Typography>

                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                    Please contact us via email at{' '}
                    <Typography component="span" color="primary" sx={{ fontWeight: 'bold' }}>
                        support@goldwaterbullion.com
                    </Typography>
                    {' '}or call us at{' '}
                    <Typography component="span" color="primary" sx={{ fontWeight: 'bold' }}>
                        415-754-9214
                    </Typography>
                    {' '}to initiate the return process.
                </Typography>

                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button 
                        variant="contained" 
                        onClick={onClose}
                        sx={{ 
                            bgcolor: 'primary.main',
                            '&:hover': {
                                bgcolor: 'primary.dark',
                            }
                        }}
                    >
                        Dismiss
                    </Button>
                </Box>
            </Box>
            <AlertNotification success={success} error={error} handleCloseAlert={handleCloseAlert} />
        </Container>
    );
};

export default FundingForm;
