import React, { useState, useEffect, useContext } from "react";
import {
  Avatar,
  Box,
  TextField,
  Button,
  Typography,
  InputAdornment,
  ButtonGroup,
  CircularProgress
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'; 
import TollIcon from '@mui/icons-material/Toll';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReviewView from '../BuyBox/ReviewView';

function Sell({
  product,
  market = {},
  initialLimitPrice,
  setLimitPrice,
  onToggleProductId,
  onShowAssetSelector,
  highestBuyPrice,
  lowestSellPrice
}) {
  const navigate = useNavigate();
  
  const SPOT_PRICE = 2500;
  const APP_FEE_PERCENTAGE = 0.056; // 5.6%
  
  const defaultPrice = market.buy || highestBuyPrice || SPOT_PRICE;
  
  const [coinInput, setCoinInput] = useState(1);
  const [limitPrice, updateLimitPrice] = useState(defaultPrice);
  const [subTotal, setSubTotal] = useState((coinInput * defaultPrice).toFixed(2));
  const [shippingFee, setShippingFee] = useState(0);
  const [appFee, setAppFee] = useState((coinInput * defaultPrice * APP_FEE_PERCENTAGE).toFixed(2));
  const [totalPrice, setTotalPrice] = useState(
    ((coinInput * defaultPrice) - (coinInput * defaultPrice * APP_FEE_PERCENTAGE)).toFixed(2)
  );
  const [inputValue, setInputValue] = useState(defaultPrice.toFixed(2));
  const [activeButton, setActiveButton] = useState(null);
  const [showProductList, setShowProductList] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // Calculate derived values
  const calculatePrices = (price, coins) => {
    const subtotal = (coins * price).toFixed(2);
    const newAppFee = (coins * price * APP_FEE_PERCENTAGE).toFixed(2);
    const total = (coins * price - (coins * price * APP_FEE_PERCENTAGE)).toFixed(2);
    return { subtotal, newAppFee, total };
  };

  // Update all price-related states
  const updateAllPrices = (newPrice, newCoinInput = coinInput) => {
    const { subtotal, newAppFee, total } = calculatePrices(newPrice, newCoinInput);
    setSubTotal(subtotal);
    setAppFee(newAppFee);
    setTotalPrice(total);
  };

  useEffect(() => {
    const newDefaultPrice = market.buy || highestBuyPrice || SPOT_PRICE;
    if (newDefaultPrice !== limitPrice) {
      updateLimitPrice(newDefaultPrice);
      setInputValue(newDefaultPrice.toFixed(2));
      updateAllPrices(newDefaultPrice);
    }
  }, [market.buy, highestBuyPrice]);

  const handleLimitButtonClick = (action) => {
    setActiveButton(action);
    let newPrice;
    switch (action) {
      case "market":
        newPrice = market.buy || highestBuyPrice || SPOT_PRICE;
        break;
      case "up1":
        newPrice = limitPrice * 1.01;
        break;
      case "up5":
        newPrice = limitPrice * 1.05;
        break;
      case "up10":
        newPrice = limitPrice * 1.1;
        break;
      default:
        newPrice = limitPrice;
    }
    updateLimitPrice(newPrice);
    setInputValue(newPrice.toFixed(2));
    updateAllPrices(newPrice);
  };

  // Keep all existing functions
  const navigateToWallet = () => {
    navigate('/wallet');
  };

  const fetchProducts = async () => {
    setLoading(true);
    const isLocal = process.env.REACT_APP_LOCAL === 'true';
    try {
      if (isLocal) {
        const mockData = await import('./fixtures/products.json');
        setProducts(mockData.default);
      } else {
        const response = await axios.get('/api/products');
        setProducts(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch products', error);
    } finally {
      setLoading(false);
    }
  };

  const handleProductSelect = (selectedProduct) => {
    navigate(`/products/${selectedProduct.slug}`);
    setShowProductList(false);
  };

  const onFinish = () => {
    const isLocal = process.env.REACT_APP_LOCAL === 'true';
    if (isLocal) {
      setShowReview(false);
      setShowConfirmation(true);
    } else {
      fetch('/orders/new', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          product_id: product.product_id,
          price: (parseFloat(limitPrice) * 100).toFixed(0),
          volume: coinInput,
          type: 'SELL',
          is_credit_card: false,
        }),
      })
        .then((response) => {
          if (response.ok) {
            setShowReview(false);
            setShowConfirmation(true);
          } else {
            response.json().then((data) => alert(`Error: ${data.message}`));
          }
        })
        .catch((error) => {
          alert('Error placing order: ' + error.message);
        });
    }
  };

  const handleReviewOrder = () => {
    setShowReview(true);
  };

  const handleBack = () => {
    setShowReview(false);
  };

  const handleDone = () => {
    setShowConfirmation(false);
  };

  // Add the handlePriceChange function outside of the JSX
  const handlePriceChange = (e) => {
    const value = e.target.value;
    if (/^-?[0-9]*(\.[0-9]*)?$/.test(value)) {
      setInputValue(value);
      const newPrice = parseFloat(value);
      if (!isNaN(newPrice)) {
        updateLimitPrice(newPrice);
        updateAllPrices(newPrice);
      }
    }
  };

  // Update the coin input handler
  const handleCoinInputChange = (e) => {
    const newCoinInput = parseInt(e.target.value) || 1; // Default to 1 if invalid
    setCoinInput(newCoinInput);
    updateAllPrices(limitPrice, newCoinInput);
  };

  // First, define the common button style with centered text
  const commonButtonStyle = {
    width: '75%',
    justifyContent: 'flex-start',
    '& .MuiButton-startIcon': {
      marginRight: 1
    },
    '& .MuiTypography-root': {  // Center the text
      width: '100%',
      textAlign: 'center'
    }
  };

  return (
    <>
        {showConfirmation ? (
          <Box sx={{ p: 3, textAlign: 'center' }}>
            <Avatar sx={{ bgcolor: 'primary.main', mx: 'auto', mb: 2 }}>
              <CheckCircleIcon />
            </Avatar>
            <Typography variant="h5" gutterBottom>Order submitted</Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              We'll email you once this order changes status.
            </Typography>
            <Button 
              variant="contained" 
              color="primary" 
              fullWidth 
              sx={{ mb: 2 }} 
              onClick={handleDone}
            >
              Done
            </Button>
          </Box>
        ) : showReview ? (
          <ReviewView
            onBack={handleBack}
            onFinish={onFinish}
            errorMessage={errorMessage}
            product={product}
            selectedTab="sell"
            subTotal={subTotal}
            inputValue={inputValue}
            coinInput={coinInput}
            shippingFee={shippingFee}
            totalPrice={totalPrice}
            appFee={appFee}
          />
        ) : showProductList ? (
          <Box sx={{ p: 3, maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden' }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Box sx={{ mt: 2 }}>
                  {products.map((item) => (
                    <Button
                      key={item.slug}
                      variant="outlined"
                      fullWidth
                      sx={{ mb: 2, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                      onClick={() => handleProductSelect(item)}
                    >
                      <Avatar
                        src={item.image_one_thumb ? item.image_one_thumb : ''}
                        alt={item.title}
                        sx={{ width: 40, height: 40, mr: 2 }}
                      />
                      <Typography noWrap sx={{ textAlign: 'left' }}>{item.title}</Typography>
                    </Button>
                  ))}
                </Box>
              </>
            )}
          </Box>
        ) : (
          <>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <TextField
                label="Coins"
                type="number"
                value={coinInput}
                onChange={handleCoinInputChange}
                InputProps={{ inputProps: { min: 1, step: 1 } }}
                sx={{ mr: 2 }}
              />
              <TextField
                label="Total Price"
                type="text"
                value={subTotal}
                InputProps={{ readOnly: true }}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="overline" sx={{ textAlign: 'left', display: 'block' }}>
                LIMIT PRICE
              </Typography>
              <TextField
                type="text"
                value={inputValue}
                onChange={handlePriceChange}
                InputProps={{
                  endAdornment: <InputAdornment position="end">USD</InputAdornment>,
                  inputProps: {
                    style: { textAlign: 'left' }
                  }
                }}
                fullWidth
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <ButtonGroup variant="contained" aria-label="Basic button group" fullWidth sx={{ mt: 2, mb: 2 }}>
                <Button
                  variant="contained"
                  color={activeButton === "market" ? "info" : "primary"}
                  onClick={() => handleLimitButtonClick("market")}
                >
                  Market
                </Button>
                <Button
                  variant="contained"
                  color={activeButton === "up1" ? "info" : "primary"}
                  onClick={() => handleLimitButtonClick("up1")}
                  startIcon={<ArrowUpwardIcon />}
                >
                  1%
                </Button>
                <Button
                  variant="contained"
                  color={activeButton === "up5" ? "info" : "primary"}
                  onClick={() => handleLimitButtonClick("up5")}
                  startIcon={<ArrowUpwardIcon />}
                >
                  5%
                </Button>
                <Button
                  variant="contained"
                  color={activeButton === "up10" ? "info" : "primary"}
                  onClick={() => handleLimitButtonClick("up10")}
                  startIcon={<ArrowUpwardIcon />}
                >
                  10%
                </Button>
              </ButtonGroup>

              {/* Sell and Wallet buttons */}
              <Box sx={{ mb: 2 }}>
                <Box
                  sx={{ 
                    display: "flex", 
                    justifyContent: "space-between", 
                    alignItems: "center", 
                    mb: 1 
                  }}
                  onClick={() => {
                    setShowProductList(true);
                    fetchProducts();
                  }}
                >
                  <Typography sx={{ width: '10%' }} variant="overline">Sell:</Typography>
                  <Button
                    variant="outlined"
                    sx={commonButtonStyle}
                    startIcon={<TollIcon />}
                  >
                    <Typography noWrap>
                      {product.title}
                    </Typography>
                  </Button>
                </Box>

                <Box
                  sx={{ 
                    display: "flex", 
                    justifyContent: "space-between", 
                    alignItems: "center"
                  }}
                >
                  <Typography sx={{ width: '10%' }} variant="overline">To:</Typography>
                  <Button
                    variant="outlined"
                    sx={commonButtonStyle}
                    startIcon={<AccountBalanceIcon />}
                    onClick={navigateToWallet}
                  >
                    <Typography noWrap>
                      Wallet
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
            <Button 
              variant="contained" 
              color="primary" 
              fullWidth 
              sx={{ mt: 2 }} 
              onClick={handleReviewOrder}
              endIcon={<ArrowForward />}
            >
              Review Order
            </Button>
          </>
        )}
    </>
  );
}

export default Sell;
