import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { SvgIcon, SvgIconProps } from '@mui/material';


const Footer = () => {
  const [trendingMarkets, setTrendingMarkets] = useState([]);
  const isLocal = process.env.REACT_APP_LOCAL === 'true';
  
  const DiscordIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z" />
    </SvgIcon>
  );
  // Fetch trending markets data
  const fetchTrendingMarkets = async () => {
    try {
      let marketsData;
      if (isLocal) {
        const staticData = await import('./fixtures/trendingMarkets.json');
        marketsData = staticData.default.trending_products || []; // Handle potential undefined
      } else {
        const response = await fetch('/api/trending-markets');
        if (!response.ok) {
          throw new Error('Error fetching trending markets');
        }
        
        // Ensure the response is not empty
        const data = (await response.json()) || {};
        marketsData = data.trending_products || []; // Safely access the array
      }
  
      // Ensure the data is an array
      setTrendingMarkets(Array.isArray(marketsData) ? marketsData : []);
    } catch (error) {
      console.error('Error fetching trending markets:', error);
      setTrendingMarkets([]); // Handle errors gracefully
    }
  };
  
    

  useEffect(() => {
    fetchTrendingMarkets();
  }, []);

  return (
    <Box component="footer" sx={{ p: 4, bgcolor: 'background.paper', borderTop: 1, borderColor: 'divider' }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Goldwater Bullion Exchange Section */}
          <Grid item xs={12} sm={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                Refund Policy
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2, textAlign: 'left', lineHeight: 1.6 }}>
                Your satisfaction is our priority. If you're not completely happy with your purchase, you can request a refund within 7 days of receiving your item.
              </Typography>
              <Box sx={{ display: 'flex', mt: 2 }}>
                <Link href="https://x.com/GoldwaterBXinc" color="inherit" sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                  <XIcon />
                </Link>
                <Link href="https://www.linkedin.com/company/goldwater-bullion-exchange-inc" color="inherit" sx={{ ml: 2, textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                  <LinkedInIcon />
                </Link>
                <Link href="https://discord.gg/S88xkvgdZf" color="inherit" sx={{ ml: 2, textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                  <DiscordIcon />
                </Link>
              </Box>
            </Box>
          </Grid>

          {/* More about Goldwater Section */}
          <Grid item xs={12} sm={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                More about Goldwater
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Link href="/blog/sell-with-confidence" color="inherit" sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                  <Typography variant="body2" color="text.secondary">Our Story</Typography>
                </Link>
                <Link href="/legal/tos" color="inherit" sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                  <Typography variant="body2" color="text.secondary">Terms of service</Typography>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Footer Bottom Section */}
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Typography variant="body2" color="text.secondary">
            © Goldwater Bullion Exchange, Inc. All rights reserved.
          </Typography>
          <Typography variant="body2" color="text.secondary">
            5900 Balcones Drive, STE 100 Austin, TX 78731
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
