import React from 'react';
import Container from '@mui/material/Container';
import Hero from './Hero';
import Main from './Main';
import GoldBar from '../GoldBar/goldbar';
import './IndexPage.css';

export default function IndexPage() {
  return (
    <Container maxWidth="xl" sx={{ padding: 0, margin: 0, width: '100%' }}>
      <Hero />
      <Main />
      <GoldBar />
    </Container>
  );
}