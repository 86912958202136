import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

function createData(name, marketplace, verifiedGold, shippingInsurance, payment) {
  return { name, marketplace, verifiedGold, shippingInsurance, payment };
}

const rows = [
  createData('Goldwater Bullion Exchange', "✅", "✅", "✅", "ACH/wire"),
  createData('APMEX', "⛔️", "✅", "✅", "ACH/wire"),
  createData('JM Bullion', "⛔️", "✅", "✅", "ACH/wire"),
  createData('eBay', "✅", "⛔️", "⛔️", "credit"),
  createData('craigslist', "⛔️", "⛔️", "⛔️", "cash"),
];

const MobileView = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = rows.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
      <Card>
        <CardContent>
          <Typography variant="h6" component="div" gutterBottom>
            {rows[activeStep].name}
          </Typography>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">Marketplace</TableCell>
                <TableCell align="right">{rows[activeStep].marketplace}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Verified Gold</TableCell>
                <TableCell align="right">{rows[activeStep].verifiedGold}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Shipping Insurance</TableCell>
                <TableCell align="right">{rows[activeStep].shippingInsurance}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Payment</TableCell>
                <TableCell align="right">{rows[activeStep].payment}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {activeStep === maxSteps - 1 ? (
              <Box sx={{ width: 64 }} /> // Invisible spacer
            ) : (
              <>
                Next
                <KeyboardArrowRight />
              </>
            )}
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            {activeStep === 0 ? (
              <Box sx={{ width: 64 }} /> // Invisible spacer
            ) : (
              <>
                <KeyboardArrowLeft />
                Back
              </>
            )}
          </Button>
        }
      />
    </Box>
  );
};

const DesktopView = () => (
  <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Service</TableCell>
          <TableCell align="right">Marketplace</TableCell>
          <TableCell align="right">Verified Gold</TableCell>
          <TableCell align="right">Shipping Insurance</TableCell>
          <TableCell align="right">Payment</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow
            key={row.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell align="right">{row.marketplace}</TableCell>
            <TableCell align="right">{row.verifiedGold}</TableCell>
            <TableCell align="right">{row.shippingInsurance}</TableCell>
            <TableCell align="right">{row.payment}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default function ResponsiveTable() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return isMobile ? <MobileView /> : <DesktopView />;
}