import React, { useState, useEffect, useContext } from "react";
import {
  Avatar,
  Box,
  Tabs,
  Tab,
  TextField,
  Button,
  Typography,
  InputAdornment,
  Divider,
  ButtonGroup,
  Card,
  CircularProgress,
  MenuItem,
  Select
} from "@mui/material";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'; 
import { useAuth } from '../../contexts/AuthContext';
import TollIcon from '@mui/icons-material/Toll';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import Sell from './Sell';  
import ReviewView from '../BuyBox/ReviewView';
import CreditCard from '../CreditCard/CreditCard';
import OrderErrorView from '../BuyBox/OrderErrorView';
import OrderConfirmationView from '../BuyBox/OrderConfirmationView';

const SPOT_PRICE = 2500;

function BuySell({
  product,
  market = {},
  initialLimitPrice,
  setLimitPrice,
  onToggleProductId,
  onShowAssetSelector,
  lowestSellPrice
}) {
  const defaultPrice = market.sell || lowestSellPrice || initialLimitPrice || 0;
  const [selectedTab, setSelectedTab] = useState("buy");
  const [coinInput, setCoinInput] = useState(1);
  const [limitPrice, updateLimitPrice] = useState(defaultPrice);

  useEffect(() => {
    const newDefaultPrice = market.sell || lowestSellPrice || initialLimitPrice || 0;
    if (newDefaultPrice !== limitPrice) {
      updateLimitPrice(newDefaultPrice);
      setInputValue(newDefaultPrice.toFixed(2));
    }
  }, [market.sell, lowestSellPrice, initialLimitPrice]);

  const [shippingFee, setShippingFee] = useState(0)
  const [totalPrice, setTotalPrice] = useState(
    (
      (coinInput * defaultPrice + shippingFee).toFixed(2)
    )
  );
  const [subTotal, setSubTotal] = useState(
    (
      (coinInput * defaultPrice).toFixed(2)
    )
  );
  const [inputValue, setInputValue] = useState(defaultPrice.toFixed(2));
  const [activeButton, setActiveButton] = useState("market");
  const [showProductList, setShowProductList] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false); 
  const [errorMessage, setErrorMessage] = useState("");
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState('credit-card');
  const [showCreditCard, setShowCreditCard] = useState(false);
  const [showOrderError, setShowOrderError] = useState(false);
  const [creditCardReturnUrl, setCreditCardReturnUrl] = useState('');

  
  useEffect(() => {
    const subtotal = coinInput * limitPrice;
    const appFee = paymentMethod === 'credit-card' ? subtotal * 0.04 : 0;
    setSubTotal(subtotal.toFixed(2));
    setTotalPrice((subtotal + shippingFee + appFee).toFixed(2));
  }, [coinInput, limitPrice, shippingFee, paymentMethod]);
  

  const navigateToWallet = () => {
    navigate('/wallet');
  };

  const fetchProducts = async () => {
    setLoading(true);
    const isLocal = process.env.REACT_APP_LOCAL === 'true';
    try {
      if (isLocal) {
        const mockData = await import('./fixtures/products.json');
        setProducts(mockData.default);
      } else {
        const response = await axios.get('/api/products');
        setProducts(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch products', error);
    } finally {
      setLoading(false);
    }
  };

  const handleProductSelect = (selectedProduct) => {
    navigate(`/products/${selectedProduct.slug}`);
    setShowProductList(false);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleLimitButtonClick = (action) => {
    setActiveButton(action);
    let newPrice;
    switch (action) {
      case "market":
        newPrice = market.sell || lowestSellPrice || defaultPrice;
        break;
      case "down1":
        newPrice = limitPrice * 0.99;
        break;
      case "down5":
        newPrice = limitPrice * 0.95;
        break;
      case "down10":
        newPrice = limitPrice * 0.9;
        break;
      default:
        newPrice = limitPrice;
    }
    updateLimitPrice(newPrice);
    setInputValue(newPrice.toFixed(2));
    const subtotal = coinInput * newPrice;
    const appFee = paymentMethod === 'credit-card' ? subtotal * 0.04 : 0;
    setSubTotal(subtotal.toFixed(2));
    setTotalPrice((subtotal + shippingFee + appFee).toFixed(2));
  };

  const onFinish = () => {
    const isLocal = process.env.REACT_APP_LOCAL === 'true';
    if (isLocal) {
      setShowReview(false);
      setShowConfirmation(true);
    } else {
      fetch('/orders/new', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          product_id: product.product_id,
          price: (parseFloat(limitPrice) * 100).toFixed(0),
          volume: coinInput,
          type: selectedTab.toUpperCase(),
          is_credit_card: paymentMethod === 'credit-card'
        }),
      })
        .then((response) => {
          if (response.ok) {
            setShowReview(false);
            setShowConfirmation(true);  
            setErrorMessage(""); 
          } else if (response.status === 400) {
            response.json().then((data) => {
              setErrorMessage(data.message);
            });
          } else {
            response.json().then((data) => alert(`Error: ${data.message}`));
          }
        })
        .catch((error) => {
          alert('Error placing order: ' + error.message);
        });
    }
  };

  const handleReviewOrder = () => {
    setShowReview(true);
  };

  const handleBack = () => {
    setErrorMessage("");
    setShowReview(false);
  };

  const handleDone = () => {
    setShowConfirmation(false);  
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const submitOrder = (orderDetails) => {
    const isLocal = process.env.REACT_APP_LOCAL === 'true';
    if (isLocal) {
      setShowOrderError(false);
      setShowConfirmation(true);
    } else {
      fetch('/orders/new', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          ...orderDetails,
          is_credit_card: paymentMethod === 'credit-card'
        }),
      })
        .then((response) => {
          if (response.ok) {
            setShowOrderError(false);
            setShowConfirmation(true);  
            setErrorMessage(""); 
          } else if (response.status === 400) {
            response.json().then((data) => {
              setErrorMessage(data.message);
            });
          } else {
            response.json().then((data) => {
              setErrorMessage(data.message);
            });
          }
        })
        .catch((error) => {
          setErrorMessage(error.message);
        });
    }
  };

  const handleReviewFinish = () => {
    if (paymentMethod === 'credit-card') {
      const basePrice = parseFloat(limitPrice);
      const orderParams = new URLSearchParams({
        order_product_id: product.product_id,
        order_price: (basePrice * 100).toFixed(0),
        order_volume: coinInput,
        order_type: selectedTab.toUpperCase(),
        is_credit_card: true
      }).toString();
      
      const returnUrl = `${window.location.href}${window.location.search ? '&' : '?'}${orderParams}`;
      setCreditCardReturnUrl(returnUrl);
      setShowCreditCard(true);
    } else {
      const orderDetails = {
        product_id: product.product_id,
        price: (parseFloat(limitPrice) * 100).toFixed(0),
        volume: coinInput,
        type: selectedTab.toUpperCase()
      };
      submitOrder(orderDetails);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const redirectStatus = params.get("redirect_status");
    const paymentIntentId = params.get("payment_intent");
    const orderPrice = params.get("order_price");

    // Check if this payment has already been processed
    const processedPayments = JSON.parse(localStorage.getItem('processedPayments') || '{}');
    
    if (redirectStatus === 'succeeded' && paymentIntentId && !processedPayments[paymentIntentId]) {
      // Mark this payment as being processed immediately
      processedPayments[paymentIntentId] = true;
      localStorage.setItem('processedPayments', JSON.stringify(processedPayments));

      fetch('/api/banking/credit-from-payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          payment_intent_id: paymentIntentId,
        }),
      })
        .then(response => {
          console.log("Credit response status:", response.status);
          return response.json();
        })
        .then(data => {
          console.log("Credit response:", data);
          if (data.success) {
            const orderDetails = {
              product_id: params.get('order_product_id'),
              price: orderPrice,
              volume: params.get('order_volume'),
              type: params.get('order_type')
            };
            submitOrder(orderDetails);
          } else {
            setErrorMessage(data.error || 'Failed to credit account');
            setShowOrderError(true);
          }
        })
        .catch(err => {
          console.error('Credit error:', err);
          setErrorMessage("Payment succeeded but failed to credit account. Please contact support.");
          setShowOrderError(true);
        });
    }
  }, []); // Empty dependency array is fine here as we're using localStorage for state

  // Add this effect to clean up old processed payments
  useEffect(() => {
    const cleanupProcessedPayments = () => {
      const processedPayments = JSON.parse(localStorage.getItem('processedPayments') || '{}');
      const oneHourAgo = Date.now() - (60 * 60 * 1000);
      
      // Remove entries older than 1 hour
      const newProcessedPayments = Object.fromEntries(
        Object.entries(processedPayments)
          .filter(([_, timestamp]) => timestamp > oneHourAgo)
      );
      
      localStorage.setItem('processedPayments', JSON.stringify(newProcessedPayments));
    };

    cleanupProcessedPayments();
  }, []);

  // This useEffect handles setting the default price when switching between buy/sell tabs
  //
  // For sell orders:
  // - Uses the market buy price if available (since we're selling to buyers)
  // - Falls back to lowest sell price in the market
  // - Uses spot price as final fallback
  //
  // For buy orders:
  // - Uses the market sell price if available (since we're buying from sellers)
  // - Falls back to lowest sell price
  // - Then tries the initial limit price
  // - Uses spot price as final fallback
  //
  // The price is updated in two places:
  // 1. The internal limit price state via updateLimitPrice()
  // 2. The displayed input value, formatted to 2 decimal places
  useEffect(() => {
    if (selectedTab === "sell") {
      const sellDefaultPrice = market.buy || lowestSellPrice || SPOT_PRICE;
      updateLimitPrice(sellDefaultPrice);
      setInputValue(sellDefaultPrice.toFixed(2));
    } else {
      const buyDefaultPrice = market.sell || lowestSellPrice || initialLimitPrice || SPOT_PRICE;
      updateLimitPrice(buyDefaultPrice);
      setInputValue(buyDefaultPrice.toFixed(2));
    }
  }, [selectedTab, market.buy, market.sell, lowestSellPrice, initialLimitPrice]);

  return (
    <Card variant="outlined">
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        {showConfirmation ? (
          <OrderConfirmationView onDone={handleDone} />
        ) : showOrderError ? (
          <OrderErrorView 
            errorMessage={errorMessage}
          />
        ) : showCreditCard ? (
          <Box sx={{ p: 1 }}>
            <CreditCard 
              amount={parseFloat(totalPrice) * 100} 
              returnUrl={creditCardReturnUrl}
            />
          </Box>
        ) : showReview ? (
          <ReviewView
            onBack={handleBack}
            onFinish={handleReviewFinish}
            errorMessage={errorMessage}
            product={product}
            selectedTab={selectedTab}
            subTotal={subTotal}
            inputValue={inputValue}
            coinInput={coinInput}
            shippingFee={shippingFee}
            totalPrice={totalPrice}
            paymentMethod={paymentMethod}
          />
        ) : showProductList ? (
          <Box sx={{ p: 3, maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden' }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Box sx={{ mt: 2 }}>
                  {products.map((item) => (
                    <Button
                      key={item.slug}
                      variant="outlined"
                      fullWidth
                      sx={{ mb: 2, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                      onClick={() => handleProductSelect(item)}
                    >
                      <Avatar
                        src={item.image_one_thumb ? item.image_one_thumb : ''}
                        alt={item.title}
                        sx={{ width: 40, height: 40, mr: 2 }}
                      />
                      <Typography noWrap sx={{ textAlign: 'left' }}>{item.title}</Typography>
                    </Button>
                  ))}
                </Box>
              </>
            )}
          </Box>
        ) : (
          <>
            <Tabs value={selectedTab} onChange={handleTabChange} centered>
              <Tab label="Buy" value="buy" />
              <Tab label="Sell" value="sell" />
            </Tabs>
            <Divider />
            <Box sx={{ p: 3 }}>
              {selectedTab === "buy" ? (
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <TextField
                      label="Coins"
                      type="number"
                      value={coinInput}
                      onChange={(e) => setCoinInput(parseInt(e.target.value))}
                      InputProps={{ inputProps: { min: 1, step: 1 } }}
                      sx={{ mr: 2 }}
                    />
                    <TextField
                      label="Total Price"
                      type="text"
                      value={subTotal}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="overline" sx={{ textAlign: 'left', display: 'block' }}>LIMIT PRICE</Typography>
                    <TextField
                      type="text"
                      value={inputValue}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^-?[0-9]*(\.[0-9]*)?$/.test(value)) {
                          setInputValue(value);
                          const newLimitPrice = parseFloat(value);
                          if (!isNaN(newLimitPrice)) {
                            updateLimitPrice(newLimitPrice);
                          }
                        }
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">USD</InputAdornment>,
                      }}
                      fullWidth
                    />
                  </Box>
                  <ButtonGroup variant="contained" aria-label="Basic button group" fullWidth>
                    <Button
                      variant="contained"
                      color={activeButton === "market" ? "info" : "primary"}
                      onClick={() => handleLimitButtonClick("market")}
                    >
                      Market
                    </Button>
                    <Button
                      variant="contained"
                      color={activeButton === "down1" ? "info" : "primary"}
                      onClick={() => handleLimitButtonClick("down1")}
                      startIcon={<ArrowDownwardIcon />}
                    >
                      1%
                    </Button>
                    <Button
                      variant="contained"
                      color={activeButton === "down5" ? "info" : "primary"}
                      onClick={() => handleLimitButtonClick("down5")}
                      startIcon={<ArrowDownwardIcon />}
                    >
                      5%
                    </Button>
                    <Button
                      variant="contained"
                      color={activeButton === "down10" ? "info" : "primary"}
                      onClick={() => handleLimitButtonClick("down10")}
                      startIcon={<ArrowDownwardIcon />}
                    >
                      10%
                    </Button>
                  </ButtonGroup>
                  <Box sx={{ mb: 2 }}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}
                    >
                      <Typography sx={{ mt: 2 }} variant="overline">Buy:</Typography>
                      <Button
                        variant="outlined"
                        sx={{ mt: 2, width: '70%' }}
                        startIcon={<TollIcon />}
                        onClick={() => {
                          setShowProductList(true);
                          fetchProducts();
                        }}
                      >
                        <Typography noWrap sx={{ maxWidth: '150px' }}>
                          {product.title}
                        </Typography>
                      </Button>
                    </Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                    >
                      <Typography variant="overline">Pay With:</Typography>
                      <Select
                        value={paymentMethod}
                        onChange={handlePaymentMethodChange}
                        variant="outlined"
                        size="small"
                        sx={{ 
                          width: '70%',
                          color: 'primary.main',
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'primary.main',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'primary.main',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'primary.main',
                          }
                        }}
                        startAdornment={
                          paymentMethod === 'credit-card' 
                            ? <CreditCardIcon sx={{ mr: 1, color: 'primary.main' }} />
                            : <AccountBalanceIcon sx={{ mr: 1, color: 'primary.main' }} />
                        }
                      >
                        <MenuItem value="wallet" sx={{ color: 'primary.main' }}>Wallet</MenuItem>
                        <MenuItem value="credit-card" sx={{ color: 'primary.main' }}>Credit Card</MenuItem>
                      </Select>
                    </Box>
                  </Box>
                  {auth?.user ? (
                    <Button 
                      variant="contained" 
                      color="success" 
                      fullWidth 
                      sx={{ mt: 2 }} 
                      onClick={handleReviewOrder}
                      endIcon={<ArrowForward />}
                    >
                      Review Bid
                    </Button>
                  ) : (
                    <Button
                      onClick={() => window.location.href = '/login'} 
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ mt: 2 }}
                      endIcon={<ArrowForward />}
                    >
                      Login to Place Bid
                    </Button>
                  )}
                </Box>
              ) : (
                <Sell
                  product={product}
                  market={market}
                  initialLimitPrice={initialLimitPrice}
                  setLimitPrice={setLimitPrice}
                  onToggleProductId={onToggleProductId}
                  onShowAssetSelector={onShowAssetSelector}
                  highestBuyPrice={market.buy}
                  lowestSellPrice={lowestSellPrice}
                />
              )}
            </Box>
          </>
        )}
      </Box>
    </Card>
  );
}

export default BuySell;
