import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

let stripePromise = null;

function CreditCard({ amount, returnUrl }) {
  const [clientSecret, setClientSecret] = useState("");
  const [error, setError] = useState(null);
  const [stripeLoaded, setStripeLoaded] = useState(false);

  // Wait for stripe key to be available
  useEffect(() => {
    const initStripe = () => {
      if (window.stripePublishableKey) {
        stripePromise = loadStripe(window.stripePublishableKey);
        setStripeLoaded(true);
      } else {
        setTimeout(initStripe, 100);
      }
    };

    initStripe();
  }, []);

  useEffect(() => {
    if (!stripeLoaded || !amount) return;

    fetch("/api/banking/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ amount }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to create payment intent');
        }
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          throw new Error(data.error);
        }
        setClientSecret(data.clientSecret);
      })
      .catch((err) => {
        console.error("Payment Intent Error:", err);
        setError(err.message);
      });
  }, [amount, stripeLoaded]);

  const appearance = {
    theme: 'stripe',
  };
  
  const loader = 'auto';

  if (!stripeLoaded) {
    return <div>Loading payment system...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="credit-card-container">
      {clientSecret && (
        <Elements options={{clientSecret, appearance, loader}} stripe={stripePromise}>
          <CheckoutForm 
            amount={amount} 
            returnUrl={returnUrl}
          />
        </Elements>
      )}
    </div>
  );
}

export default CreditCard;