import React, { useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { Box, Button, Typography, CircularProgress, Alert } from '@mui/material';

const CheckoutForm = ({ amount, returnUrl }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const options = {
    layout: {
      type: 'accordion',
      defaultCollapsed: false,
      radios: true,
      spacedAccordionItems: false
    },
    paymentMethodOrder: ['card'],
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submit handler triggered", { returnUrl });

    if (!stripe || !elements) {
      console.log("Stripe not initialized");
      return;
    }

    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });

    if (error) {
      console.error("Payment confirmation error:", error);
      setMessage(error.message);
    }

    setIsProcessing(false);
  };

  return (
    <Box sx={{ maxWidth: 500, margin: '0 auto', padding: 0 }}>
      <form onSubmit={handleSubmit}>        
        <PaymentElement options={options} />
        {message && (
          <Alert 
            severity={message.includes('succeeded') ? 'success' : 'error'}
            sx={{ mt: 2 }}
          >
            {message}
          </Alert>
        )}

        <Button
          variant="contained"
          disabled={isProcessing || !stripe || !elements}
          type="submit"
          fullWidth
          sx={{ mt: 3 }}
        >
          {isProcessing ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CircularProgress size={24} sx={{ mr: 1 }} />
              Processing...
            </Box>
          ) : (
            `Pay $${(amount / 100).toFixed(2)}`
          )}
        </Button>
      </form>
    </Box>
  );
};

export default CheckoutForm; 