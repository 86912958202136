import React, { useState } from 'react';
import { Box, Modal, Fade, Backdrop } from '@mui/material';
import ResponsiveAppBar from '../NavBar/ResponsiveAppBar';
import Footer from './Footer'; // Adjust the import path as necessary
import FundingForm from '../IndexPage/FundAccount'; // Import the FundingForm component
import { useLocation } from 'react-router-dom'; // Import useLocation

const AppLayout = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [showFundForm, setShowFundForm] = useState(false); // State for showing the fund form
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // State to manage button disable
  const sessionData = window.sessionData || {};

  const location = useLocation(); // Get the current location

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleFundAccountClick = () => {
    setShowFundForm(true);
    setIsButtonDisabled(false); // Re-enable the button when reopening the modal
  };

  const handleClose = () => {
    setShowFundForm(false); // Close the modal
  };

  // Determine if the footer should be displayed
  const shouldShowFooter = !location.pathname.startsWith('/partner/onboarding') && !location.pathname.startsWith('/return') && !location.pathname.startsWith('/review') && !location.pathname.startsWith('/withdraw') && !location.pathname.startsWith('/blog/sell-with-confidence');

  return (
    <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <ResponsiveAppBar onFundAccountClick={handleFundAccountClick} />
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', flex: '1 0 auto', mb: 4 }}>
        <Box sx={{ width: '100%' }}>
          {children}
        </Box>
      </Box>
      {shouldShowFooter && <Footer />} {/* Conditionally render the footer */}
      
      <Modal
        open={showFundForm}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
        <Fade in={showFundForm}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '65%',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              outline: 'none',
            }}
          >
            {showFundForm && (
              <FundingForm
                onClose={handleClose}
                isButtonDisabled={isButtonDisabled}
                setIsButtonDisabled={setIsButtonDisabled}
              />
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default AppLayout;
