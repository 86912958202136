import React from 'react';
import {
  Box,
  Typography,
  Alert,
} from '@mui/material';

function OrderErrorView({ 
  errorMessage,  
}) {
  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      </Box>
      <Alert severity="error" sx={{ mb: 3 }}>
        {errorMessage}
      </Alert>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Unable to complete your order. Please try again.
      </Typography>
    </Box>
  );
}

export default OrderErrorView; 