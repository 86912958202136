import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const steps = ['Verify Identity', 'Fund Your Account', 'Place a Bid', 'Receive Your Gold'];

const BuyProcess = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return 'We take your safety seriously. First, verify your identity and address to ensure your gold is shipped to you.';
      case 1:
        return 'Before placing a bid, make sure your account is funded. This ensures that your bid is taken seriously and guarantees the funds are available when your order is matched.';
      case 2:
        return 'Now, it\'s time to place your bid. You name the price. Your funds will be temporarily held, but don\'t worry—you can cancel at any time without any fees. Your order will clear instantly if it matches a seller\'s ask.';
      case 3:
        return 'Once the gold is verified by our expert numismatists, it will be shipped to you via fully insured FedEx or UPS express mail. Your payment is only released after this verification process.';
      default:
        return 'Unknown step';
    }
  };

  const DesktopView = () => (
    <Card sx={{ width: '100%', padding: 2, boxSizing: 'border-box', display: isMobile ? 'none' : 'block' }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom sx={{ mb: 4 }}>
          BUYING PROCESS
        </Typography>
        <Stepper activeStep={activeStep} sx={{ pb: 3 }}>
          {steps.map((label, index) => (
            <Step key={label} completed={false}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <Box sx={{ textAlign: 'center' }}>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - Your purchase is complete.
            </Typography>
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        ) : (
          <Box>
            <Typography sx={{ mt: 2, mb: 1, textAlign: 'left' }}>
              {getStepContent(activeStep)}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              {activeStep === steps.length - 1 ? null : (
                <Button onClick={handleNext}>
                  {activeStep === steps.length - 1 ? '' : 'Next'}
                </Button>
              )}
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );

  const MobileView = () => (
    <Card sx={{ width: '100%', padding: 2, boxSizing: 'border-box', display: isMobile ? 'block' : 'none' }}>
      <CardContent>
        <Typography variant="h6" component="div" gutterBottom sx={{ mb: 2, textAlign: 'center' }}>
          BUYING PROCESS
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 2, textAlign: 'center' }}>
          {steps[activeStep]}
        </Typography>
        <Typography variant="body2" sx={{ mb: 2, minHeight: 80, textAlign: 'left' }}>
          {getStepContent(activeStep)}
        </Typography>
        {activeStep === steps.length ? (
          <Box sx={{ textAlign: 'center' }}>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - Your purchase is complete.
            </Typography>
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        ) : (
          <MobileStepper
            variant="text"
            steps={steps.length}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === steps.length - 1}
              >
                Next
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />
        )}
      </CardContent>
    </Card>
  );

  return (
    <>
      <DesktopView />
      <MobileView />
    </>
  );
};

export default BuyProcess;
